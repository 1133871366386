/*--------------------
Footer Styles 
----------------------*/

.footer-wrapper {
	position: relative;
}

.footer-left {
	background: linear-gradient(
		145deg,
		var(--color-primary) 0%,
		var(--color-secondary) 100%
	);
	padding: 120px 120px;
	position: relative;
	z-index: 2;
	margin-right: -15px;
	border-top-right-radius: 6px;

	@media #{$lg-layout} {
		padding: 120px 80px;
	}

	@media #{$md-layout} {
		padding: 60px 70px;
	}

	@media #{$sm-layout} {
		padding: 60px 30px;
	}

	&::before {
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		background-image: url('/assets/images/pattern-1.png');
		content: '';
		z-index: 1;
		opacity: 0.5;
		border-top-right-radius: 6px;
	}

	.inner {
		z-index: 5;
		position: relative;

		&::after {
			//content: "tryDO";
			content: url(/assets/images/logo/big-logo.png);
			position: absolute;
			right: 0;
			top: 50%;
			z-index: -1;
			font-size: 240px;
			font-weight: 900;
			color: rgba(0, 0, 0, 0.1);
			transform: translateY(-50%);
			width: 295px;
			height: 301px;
		}

		> span {
			color: #fff;
			font-size: 15px;
			text-transform: uppercase;
			letter-spacing: 4px;
			display: block;
			margin-bottom: 9px;

			@media #{$large-mobile} {
				letter-spacing: 1px;
				margin-bottom: 9px;
			}
		}

		h2 {
			color: #ffffff;
			font-size: 75px;
			font-weight: 900;
			font-family: 'Montserrat', sans-serif;
			line-height: 90px;

			@media #{$lg-layout} {
				font-size: 50px;
				line-height: 61px;
			}

			@media #{$sm-layout} {
				font-size: 45px;
				line-height: 51px;
			}
		}

		a {
			&.rn-button-style--2 {
				margin-top: 30px;
				border-color: #ffffff;
				color: #ffffff;

				@media #{$sm-layout} {
					margin-top: 10px;
					padding: 10px 23px;
				}

				@media #{$large-mobile} {
					padding: 7px 16px;
				}

				&:hover {
					background: #fff;
					border-color: #fff;
					color: var(--color-primary);
				}
			}
		}
	}
}

.footer-style-01 {
	.footer-link {
		h4 {
			font-weight: 500;
			margin-bottom: 30px;
			color: var(--color-white);
			font-size: 20px;
			@media #{$sm-layout} {
				margin-bottom: 14px;
			}
		}
		ul {
			&.ft-link {
				padding: 0;
				margin: 0;
				list-style: none;
				li {
					color: var(--color-gray);
					font-size: 16px;
					a {
						@extend %transition;
						@extend .im-hover;
						color: var(--color-gray);
						font-size: 16px;
						padding: 5px 0;
						&:hover {
							color: var(--color-primary);
						}
					}

					& + li {
						margin-top: 13px;

						@media #{$sm-layout} {
							margin-top: 8px;
						}
					}
				}
			}
		}
	}

	.ft-text {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: 100%;
		.logo {
			margin-bottom: 30px;
		}
		p {
			color: var(--color-gray);
			font-size: 14px !important;
			line-height: 30px;
			letter-spacing: 1px;
			text-align: center;
			a {
				font-size: 14px;
				color: var(--color-gray);
				@extend .im-hover;
				&:hover {
					color: var(--color-primary);
				}
			}
		}
	}
}

.footer-style-2,
.footer-area,
.footer-style-3 {
	.logo {
		a {
			display: flex;
			justify-content: center;
			img {
				max-height: 60px;
			}
		}
	}
}

/*------------------------
    Footer Style One  
-------------------------*/

/* Footer Style Two  */
.footer-style-2 {
	p {
		color: var(--color-gray);
		font-size: 14px;
	}
}

/* Footer Style Three  */
.footer-style-3 {
	.logo {
		margin-bottom: 30px;
		margin-top: 60px;
		padding-top: 60px;
		position: relative;
		&::before {
			position: absolute;
			content: '';
			max-width: 1230px;
			height: 2px;
			left: 0;
			top: 0;
			background: #353131;
			right: 0;
			margin: 0 auto;
		}
	}
	.social-share {
	}
	.text {
		p {
			font-size: 14px;
			a {
				transition: 0.3s;
				&:hover {
					color: var(--color-primary);
				}
			}
		}
	}
	.callto-action-area {
		.title {
			@media #{$sm-layout} {
				font-size: 32px;
			}
		}
	}
}

/* Copyright Area  */
.copyright-text {
	position: absolute;
	bottom: -114px;

	@media #{$sm-layout} {
		position: static;
		bottom: 0;
		padding-top: 33px;
	}

	@media #{$md-layout} {
		position: static;
		bottom: 0;
		padding-top: 33px;
	}

	p {
		color: var(--color-gray);
		font-size: 14px;
		opacity: 0.75;
	}
}
