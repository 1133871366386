/*====================
Mainmenu Area 
=====================*/

.mainmenunav {
	display: flex;
	ul {
		&.mainmenu {
			display: flex;
			justify-content: flex-end;
			@extend %liststyle;
			flex-wrap: wrap;
			align-items: center;
			color: #fff;

			> ul {
				&.inner-ul {
					display: none;
					padding: 0;

					@media #{$sm-layout} {
						display: flex;
					}

					li {
						margin: 0 12px !important;

						> a {
							color: var(--color-white);
						}
					}

					li:first-child {
						margin-left: 0 !important;
					}
				}
			}
			> li {
				margin: 0 15px;
				position: relative;
				@extend %transition;
				color: var(--color-white);
				font-size: 16px;
				font-weight: 500;
				padding: 20px 0;
				display: block;
				cursor: pointer;
				@media #{$lg-layout} {
					margin: 0 12px;
				}

				&:hover {
					color: var(--color-primary);

					@media #{$md-layout} {
						color: var(--color-primary);
					}
					@media #{$sm-layout} {
						color: var(--color-primary);
					}
				}

				> a {
					color: #fff;
				}
				> ul {
					&.submenu {
						@extend %liststyle;
						min-width: 240px;
						height: auto;
						position: absolute;
						top: 100%;
						left: 0;
						z-index: 90;
						opacity: 0;
						visibility: hidden;
						background-color: #fff;
						box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
						text-align: left;
						@extend %transition;
						padding: 12px 0;
						border-radius: 4px;
						li {
							a {
								font-size: 16px;
								font-weight: 500;
								padding: 5px 20px;
								font-size: 14px;
								display: block;
								color: $heading-color;
								margin: 0 10px;
								border-radius: 3px;
								@extend %transition;
							}
							&:hover {
								a {
									color: var(--color-primary);
									background: rgba(249, 0, 77, 0.07);
								}
							}
						}
					}
				}
				&:hover {
					> ul {
						&.submenu {
							opacity: 1;
							visibility: visible;
						}
					}
				}

				&:hover {
					> a {
						color: var(--color-primary);
					}
				}
				&:first-child {
					margin-left: 0;
				}
				&:last-child {
					margin-right: 0;
				}
			}
		}
		&.mobile-icons {
			display: flex;
			justify-content: flex-end;
			@extend %liststyle;
			flex-wrap: wrap;
			align-items: center;
			color: #fff;

			> span {
				margin-left: 5px;
			}

			> li {
				margin: 0 15px;
				position: relative;
				@extend %transition;
				color: var(--color-white);
				font-size: 16px;
				font-weight: 500;
				padding: 20px 0;
				display: block;
				cursor: pointer;
				@media #{$lg-layout} {
					margin: 0 12px;
				}

				&:hover {
					color: var(--color-primary);

					@media #{$md-layout} {
						color: var(--color-primary);
					}
					@media #{$sm-layout} {
						color: var(--color-primary);
					}
				}

				> a {
					color: #fff;
				}
				> ul {
					&.submenu {
						@extend %liststyle;
						min-width: 240px;
						height: auto;
						position: absolute;
						top: 100%;
						left: 0;
						z-index: 90;
						opacity: 0;
						visibility: hidden;
						background-color: #fff;
						box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
						text-align: left;
						@extend %transition;
						padding: 12px 0;
						border-radius: 4px;
						li {
							a {
								font-size: 16px;
								font-weight: 500;
								padding: 5px 20px;
								font-size: 14px;
								display: block;
								color: $heading-color;
								margin: 0 10px;
								border-radius: 3px;
								@extend %transition;
							}
							&:hover {
								a {
									color: var(--color-primary);
									background: rgba(249, 0, 77, 0.07);
								}
							}
						}
					}
				}
				&:hover {
					> ul {
						&.submenu {
							opacity: 1;
							visibility: visible;
						}
					}
				}

				&:hover {
					> a {
						color: var(--color-primary);
					}
				}
				&:first-child {
					margin-left: 0;
				}
				&:last-child {
					margin-right: 0;
				}
			}

			@media #{$sm-layout} {
				display: none;
			}
		}
	}
}

.humberger-menu {
	span {
		&.text-white {
			color: #c6c9d8 !important;
		}
	}
}

.color-white {
	.mainmenunav {
		ul {
			&.mainmenu {
				@extend %liststyle;
				> li {
					> a {
						color: #ffffff;
					}
					&:hover {
						> a {
							color: rgba(255, 255, 255, 0.6);
							@media #{$md-layout} {
								color: var(--color-primary);
							}
							@media #{$sm-layout} {
								color: var(--color-primary);
							}
						}
					}
				}
			}
		}
	}
}

.color-black {
	.mainmenunav {
		ul {
			&.mainmenu {
				@extend %liststyle;
				> li {
					> a {
						color: rgba(29, 29, 36, 1);
					}
					&:hover {
						> a {
							color: var(--color-primary);
						}
					}
				}
			}
		}
	}
}

@media #{$lg-layout} {
	.header-style-two .humberger-menu span.text-white {
		color: $body-color !important;
	}
}

@media #{$md-layout} {
	.header-style-two .humberger-menu span.text-white {
		color: $body-color !important;
	}
}

@media #{$sm-layout} {
	.header-style-two .humberger-menu span.text-white {
		color: $body-color !important;
	}
}
