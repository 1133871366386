/*====================
    Breadcrump Area 
======================*/
.breadcrumb-inner {
	text-align: center;
	h2 {
		&.title {
			color: var(--color-white);
			margin-bottom: 5px;
		}
	}
	ul {
		&.page-list {
			@extend %liststyle;
			li {
				display: inline-block;
				color: #fff;
				padding: 0 12px;
				position: relative;
				font-size: 14px;
				&::after {
					position: absolute;
					content: '';
					top: 50%;
					transform: translateY(-50%);
					background-color: #fff;
					width: 5px;
					height: 5px;
					border-radius: 100%;
					opacity: 0.5;
					right: -3px;
				}
				a {
					color: #fff;
					transition: 0.5s;
					&:hover {
						color: var(--color-primary);
					}
				}
				&.rn-breadcrumb-item {
					&.active {
						color: var(--color-white);
						&::after {
							display: none;
						}
					}
				}
			}
		}
	}
}

.breadcrupm-style--2 {
	h2 {
		&.title {
			text-transform: uppercase;
			font-size: 72px;
			@media #{$sm-layout} {
				font-size: 40px;
				line-height: 59px;
			}
		}
	}
	span {
		font-size: 22px;
		color: #c6c9d8;
	}
}

.rn-page-title {
	h2 {
		&.title {
			font-size: 72px;
			@media #{$lg-layout} {
				font-size: 56px;
			}
			@media #{$md-layout} {
				font-size: 50px;
			}
			@media #{$sm-layout} {
				font-size: 40px;
				line-height: 59px;
			}
		}
	}
	p {
		font-size: 22px;
		line-height: 27px;
		color: #c6c9d8;
	}
}

.breadcrumb-area {
	position: relative;
	z-index: 1;
	&::before {
		position: absolute;
		content: '';
		height: 100%;
		width: 100%;
		z-index: -1;
		background: rgb(142, 0, 189);
		background: linear-gradient(
			0deg,
			rgba(142, 0, 189, 1) 0%,
			rgba(65, 10, 125, 1) 100%
		);
		top: 0;
		left: 0;
	}
}
.backto-top {
	> div {
		z-index: 999;
		width: 50px;
		height: 50px;
		line-height: 46px;
		border-radius: 50%;
		background-color: #ffffff;
		text-align: center;
		overflow: hidden;
		z-index: 999 !important;
		box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
			0 3px 1px -2px rgba(0, 0, 0, 0.2);
	}
	svg {
		font-size: 14px !important;
		color: #222222;
		width: 27px;
		height: 27px;
	}
}
